import styled from "styled-components";

export default styled.div<StyledType>`
  .section {
    .box {
      width: 80%;
      &.mobile {
        width: 100%;
      }
      margin-left: auto;
      margin-right: auto;
      .status {
        margin: 1rem 0 0.5rem 0;
      }
    }
  }
`;

type StyledType = {
  isTabletOrMobile: boolean;
};
