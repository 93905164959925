import React from "react";
import { Link } from "react-router-dom";

import Styled from "./style";

import cat from "assets/cat.jpg";

const About = () => {
  return (
    <Styled>
      <section className="section">
        <div className="container">
          <img src={cat} width="500" alt="Cat" />
          <h4 className="title is-4">
            Patiland sokaktaki ve evdeki hayvanlar için sahiplendirme & bakıcı
            bulma ve diğer tüm ilanların paylaşılabildiği bir platformdur.
          </h4>
        </div>
        <br />
        <br />
        <b>Aklınıza takılan bir şey mi var?</b>
        <br />
        <Link to="/contact">
          <button className="button is-info is-rounded">
            <span className="icon is-small">
              <i className="fas fa-envelope"></i>
            </span>
            <span>Hemen iletişime geçin</span>
          </button>
        </Link>
      </section>
    </Styled>
  );
};

export default About;
