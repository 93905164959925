import styled from "styled-components";
import { grayLight } from "colors";
import pet from "assets/pet.jpg";

export default styled.div`
  .error {
    color: #f04242;
  }
  .success {
    color: #48d15c;
  }
  .link {
    cursor: pointer;
  }
  .auth {
    margin: 0px;
    min-height: 100vh;
    .login {
      padding: 50px;
      .login-logo {
        width: 100px;
        margin-bottom: 30px;
      }
      .button {
        margin: 20px 0 40px 0;
        width: 180px;
      }
      h3 {
        margin: 40px 0 0;
      }
      ul {
        list-style-type: none;
        padding: 0;
      }
      li {
        display: inline-block;
        margin: 0 10px;
      }
      a {
        color: #42b983;
      }
    }
    background-color: white;
    overflow: hidden;
    position: relative;
    width: 100%;
    .bg {
      height: 100%;
      background-image: url(${pet});
      background-size: cover;
      position: absolute;
      right: 0;
      top: 0;
    }
    &.mobile,
    &.tablet {
      .login {
        .login-logo {
          width: 100px;
        }
      }
      .bg {
        display: none;
      }
    }
  }

  .section {
    height: 100%;
    background-color: ${grayLight};
  }

  *:focus {
    outline: none;
  }

  a {
    -webkit-tap-highlight-color: transparent;
  }
`;
