import styled from "styled-components";

export default styled.div`
  .container {
    min-height: 500px;
    .description {
      margin-top: 10px;
    }
    .contact {
      margin-top: 20px;
    }
  }
`;
