/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import firebase from "firebase/compat/app";
import { Navbar, Footer, ScrollTop, Loading } from "components";
import {
  Home,
  About,
  Login,
  Signup,
  PasswordReset,
  AdDetails,
  Ads,
  PublishAd,
  Contact,
} from "pages";
import { ROUTES } from "app_constants";

const {
  HOME,
  ABOUT,
  CONTACT,
  PUBLISH_AD,
  AD_DETAILS,
  ADS,
  AUTH,
  LOGIN,
  SIGNUP,
  PASSWORD_RESET,
} = ROUTES;

export default () => {
  const [auth, setAuthState] = useState({
    authenticated: false,
    initializing: true,
  });

  useEffect(
    () =>
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          setAuthState({
            authenticated: true,
            initializing: false,
          });
        } else {
          setAuthState({
            authenticated: false,
            initializing: false,
          });
        }
      }),
    [setAuthState]
  );

  if (auth.initializing) return <Loading />;

  return (
    <Router>
      <ScrollTop>
        <Switch>
          <Route
            path={AUTH}
            render={() => <AuthContainer authenticated={auth.authenticated} />}
          />
          <Route
            component={() => (
              <DefaultContainer authenticated={auth.authenticated} />
            )}
          />
        </Switch>
      </ScrollTop>
    </Router>
  );
};

const AuthContainer = ({ authenticated }) => (
  <>
    <Route
      exact
      path={LOGIN}
      render={() => (authenticated ? <Redirect to={ROUTES.HOME} /> : <Login />)}
    />
    <Route
      exact
      path={SIGNUP}
      render={() =>
        authenticated ? <Redirect to={ROUTES.HOME} /> : <Signup />
      }
    />
    <Route
      exact
      path={PASSWORD_RESET}
      render={() =>
        authenticated ? <Redirect to={ROUTES.HOME} /> : <PasswordReset />
      }
    />
  </>
);

const DefaultContainer = ({ authenticated }) => (
  <>
    <Navbar authenticated={authenticated} />

    <Route exact path={HOME}>
      <Home />
    </Route>
    <Route exact path={ABOUT}>
      <About />
    </Route>
    <Route exact path={CONTACT}>
      <Contact />
    </Route>
    <Route exact path={PUBLISH_AD}>
      {authenticated ? <PublishAd /> : <Redirect to={ROUTES.LOGIN} />}
    </Route>
    <Route exact path={AD_DETAILS}>
      <AdDetails />
    </Route>
    <Route exact path={ADS}>
      <Ads />
    </Route>

    <Footer />
  </>
);
