/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ValueType } from "react-select";

import { cities } from "app_constants";
import { getAds as getAdsService } from "services/ad";
import { filterAds as filterAdsService } from "services/ad";
import { Ad, OptionType } from "types";
import HomeView from "./view";

const Home = () => {
  const [localAds, setLocalAds] = useState<Ad[]>([]);
  const [searched, setSearched] = useState<boolean>(false);
  const [city, setCity] = useState();
  let { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    const category = query.get("category");
    if (category) filterByCategory(category);
    else loadAds();
  }, [search]);

  const loadAds = async () => {
    const ads: any = await getAdsService();
    if (ads !== "error") setLocalAds(ads);
    setSearched(true);
  };

  const filterByCity = async (city: any) => {
    if (city) {
      setCity(city);
      const ads: any = await filterAdsService("city", city.value);
      if (ads !== "error") setLocalAds(ads);
    }
  };

  const filterByCategory = async (category: string) => {
    const ads: any = await filterAdsService("category", category);
    if (ads !== "error") setLocalAds(ads);
  };

  return (
    <HomeView
      cities={cities}
      filterByCity={filterByCity}
      setCity={setCity}
      searched={searched}
      localAds={localAds}
      city={city}
      loadAds={loadAds}
    />
  );
};

export default Home;
