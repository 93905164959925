import styled from "styled-components";

export default styled.div<StyledType>`
  .section {
    .box {
      width: ${({ isTabletOrMobile }) => (isTabletOrMobile ? "100%" : "80%")};
      margin-left: auto;
      margin-right: auto;
      .status {
        margin: 0 0 2rem 0;
      }
      .file {
        margin: 30px 0 20px 0;
      }
      .files {
        margin: 20px 0 20px 0;
      }
      .textarea {
        min-height: 200px;
      }
    }
  }
`;

type StyledType = {
  isTabletOrMobile: boolean;
};
