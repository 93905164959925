import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";

import { Button, TextInput, Loading } from "components";
import { login as LoginService } from "services/auth";
import useAsyncImage from "hooks/useAsyncImage";
import { ROUTES } from "app_constants";
import Styled from "./style";

import pet from "assets/pet.jpg";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [error, setError] = useState(false);
  const history = useHistory();
  const imageLoaded = useAsyncImage(pet);

  const login = async () => {
    const result = await LoginService(email, password);
    if (result && result !== "error") history.push("/");
    else setError(true);
  };

  return (
    <Styled>
      {imageLoaded ? (
        <div className="auth columns is-vcentered">
          <div className="login column is-4">
            <div className="has-text-centered">
              <Link to={ROUTES.LOGIN}>
                <img className="login-logo" src="/icon.png" alt="logo" />
              </Link>
            </div>
            <TextInput
              label="E-posta"
              placeholder="deneme@patiland.io"
              value={email}
              onChange={setEmail}
            />
            <TextInput
              label="Şifre"
              placeholder="*********"
              isPassword
              value={password}
              onChange={setPassword}
            />
            <TextInput
              label="Şifre Tekrarı"
              placeholder="*********"
              isPassword
              value={password2}
              onChange={setPassword2}
            />
            {error && (
              <p className="error">Bir hata oluştu. Lütfen tekrar deneyiniz!</p>
            )}
            <Button title="Kayıt Ol" isOutlined onClick={login} />
            <div className="has-text-centered">
              <Link to={ROUTES.LOGIN} className="signup">
                Giriş Yapın
              </Link>
              <Link to={ROUTES.PASSWORD_RESET}>Şifrenizi mi unuttunuz?</Link>
            </div>
          </div>
          <div id="particles-js" className="bg column is-8"></div>
        </div>
      ) : (
        <Loading />
      )}
    </Styled>
  );
};

export default Signup;
