/* eslint-disable react-hooks/rules-of-hooks */
import { useMediaQuery } from "react-responsive";

export const isDesktopOrLaptop = () => useMediaQuery({ minDeviceWidth: 1224 });
export const isBigScreen = () => useMediaQuery({ minDeviceWidth: 1824 });
export const isTabletOrMobile = () => useMediaQuery({ maxWidth: 1224 });
export const isTabletOrMobileDevice = () =>
  useMediaQuery({ maxDeviceWidth: 1224 });
export const isPortrait = () => useMediaQuery({ orientation: "portrait" });
export const isRetina = () => useMediaQuery({ minResolution: "2dppx" });
