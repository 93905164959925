export const truncate = (text: string, value: number) => {
  return text.length > value ? text.substr(0, value - 1) + "..." : text;
};

export const isEmpty = (text: string) => !text || text === "";

export const imageAsyncLoader = (url: string): Promise<boolean> => {
  const img = new Image();

  return new Promise((res, rej) => {
    img.onload = () => {
      res(true);
    };
    img.src = url;
  });
};
