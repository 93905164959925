import React from "react";

const TextInput = ({
  isPassword = false,
  label,
  value,
  placeholder,
  onChange,
}: TextInputProps) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control has-icons-left">
        <input
          className="input"
          type={isPassword ? "password" : "text"}
          value={value}
          placeholder={placeholder}
          onChange={(e) => onChange(e.target.value)}
        />
        <span className="icon is-small is-left">
          {isPassword ? (
            <i className="fa fa-user"></i>
          ) : (
            <i className="fa fa-key"></i>
          )}
        </span>
      </div>
    </div>
  );
};

type TextInputProps = {
  isPassword?: boolean;
  value: string;
  label: string;
  placeholder: string;
  onChange: any;
};

export default TextInput;
