/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ImageGallery, Loading } from "components";
import { Ad } from "types";
import { getAd as getAdService } from "services/ad";
import Styled from "./style";

const AdDetails = () => {
  const [localAd, setLocalAd] = useState<Ad["data"] | null>(null);
  const { id } = useParams<Params>();

  useEffect(() => {
    loadAd();
  }, []);

  const loadAd = async () => {
    const adDetails: any = await getAdService(id);
    if (adDetails !== "error") setLocalAd(adDetails);
  };

  return (
    <Styled>
      <section className="section">
        <div className="container">
          {localAd ? (
            <div className="columns">
              <div className="column">
                <ImageGallery images={localAd.files} />
              </div>
              <div className="column">
                <div className="box">
                  <b className="title">{localAd.title}</b>
                  <p className="description">{localAd.description}</p>
                  <div className="contact">
                    <b>İletişim: </b>
                    <span>{localAd.contactInfo || "-"}</span>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <Loading />
          )}
        </div>
      </section>
    </Styled>
  );
};

type Params = {
  id: string;
};

export default AdDetails;
