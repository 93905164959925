import React, { MouseEvent } from "react";
import Select from "react-select";

import { Card } from "components";
import { truncate } from "utils/general";
import { getImageURL } from "utils/firebase";
import { Ad } from "types";
import Styled from "./style";

import searchVector from "assets/search.png";

const HomeView = ({
  cities,
  filterByCity,
  setCity,
  searched,
  localAds,
  city,
  loadAds,
}: HomeViewProps) => {
  return (
    <Styled>
      <section className="section">
        <div className="container">
          <div className="topSection">
            <div className="actions">
              <Select
                onChange={(value: any) => setCity(value)}
                className="selectCity action"
                placeholder="Şehir Seçiniz"
                options={
                  cities.map((city: any) => {
                    return {
                      label: city,
                      value: city,
                    };
                  }) as any
                }
              />
              <button
                className="button is-info is-rounded action"
                onClick={() => filterByCity(city)}
              >
                <span className="icon">
                  <i className="fas fa-location-arrow"></i>
                </span>
                <span>Şehre Göre Filtrele</span>
              </button>
              <button
                className="button is-info is-rounded action"
                onClick={loadAds}
              >
                <span className="icon">
                  <i className="fas fa-list-alt"></i>
                </span>
                <span>Tüm İlanları Göster</span>
              </button>
            </div>
            <p className="info">
              Yaşadığınız şehri seçtikten sonra "Şehir Seç" butonuna tıklayıp
              şehrinize özel ilanları görebilirsiniz!
            </p>
          </div>
          {searched && localAds.length === 0 ? (
            <div>
              <img
                src={searchVector}
                className="searchVector"
                alt="No Search Result"
              />
              <p>
                <b>Hiç ilan bulunamadı :(</b>
              </p>
            </div>
          ) : (
            <>
              <h3 className="title is-3">İlanlar</h3>
              <div className="columns is-multiline">
                {localAds.map(
                  ({
                    id,
                    data: {
                      title,
                      category,
                      petType,
                      description,
                      date,
                      files,
                    },
                  }: any) => (
                    <div
                      className="column is-half-tablet is-one-quarter-desktop"
                      key={id}
                    >
                      <Card
                        title={title}
                        summary={truncate(description, 60)}
                        date={new Date(date).toLocaleDateString()}
                        getImageURL={getImageURL}
                        image={files ? files[0] : null}
                        link={`/ad/${id}`}
                      />
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </div>
      </section>
    </Styled>
  );
};

type HomeViewProps = {
  cities: Array<string>;
  filterByCity: Function;
  setCity: Function;
  searched: boolean;
  localAds: Array<Ad>;
  city: any;
  loadAds: (event: MouseEvent) => void;
};

export default HomeView;
