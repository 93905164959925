/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ImageGallery from "react-image-gallery";
import { getImageURL } from "utils/firebase";

const Carousel = ({
  showPlayButton = false,
  showFullscreenButton = false,
  images = [],
}: CarouselProps) => {
  const [localImages, setLocalImages] = useState<string[]>([]);
  const [fullScreen, setFullScreen] = useState(false);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    if (images.length !== 0) loadImages();
  }, []);

  const loadImages = async () => {
    const result = [];
    for (const image of images) {
      const url = await getImageURL(image);
      result.push(url);
    }
    setLocalImages(result);
    setLoaded(true);
  };

  return (
    <Styled loaded={loaded} fullScreen={fullScreen}>
      <div className="imageGallery">
        <ImageGallery
          items={localImages.map((image) => {
            return {
              original: image,
              thumbnail: image,
            };
          })}
          showPlayButton={showPlayButton}
          showFullscreenButton={loaded}
          onScreenChange={(value) => setFullScreen(value as any)}
          onImageLoad={() => setImageCount(imageCount + 1)}
        />
      </div>
      {!loaded && <b>Yükleniyor...</b>}
    </Styled>
  );
};

const Styled = styled.div<StyledType>`
  .imageGallery {
    display: ${({ loaded }) => (loaded ? "initial" : "none")};
    .image-gallery-image {
      ${({ fullScreen }) =>
        fullScreen
          ? "max-height: calc(100vh - 100px)"
          : "height: 400px; object-fit: cover;"}
    }
    .image-gallery-thumbnail-image {
      height: 80px;
      object-fit: cover;
    }
  }
`;

type CarouselProps = {
  showPlayButton?: boolean;
  showFullscreenButton?: boolean;
  images: any;
};

type StyledType = {
  loaded?: boolean;
  fullScreen?: boolean;
};

export default Carousel;
