import firebase from "firebase/compat/app";
import "firebase/compat/storage";

export const multipleFileUpload = () => {
  const storage = firebase.storage();
  storage.ref().constructor.prototype.putFiles = (files) => {
    return Promise.all(
      files.map((file) => {
        const storageRef = storage.ref(`images/${file.name}`);
        return storageRef.put(file);
      })
    );
  };
};

export const getUser = () => {
  return new Promise((resolve) => {
    firebase.auth().onAuthStateChanged((user) => resolve(user));
  });
};

export const getImageURL = async (name) => {
  const storage = firebase.storage();
  return storage.ref(`images/${name}`).getDownloadURL();
};
