import React, { useState } from "react";

import { publishAd as publishAdService } from "services/ad";
import { isEmpty } from "utils/general";
import { Ad } from "types";
import PublishAdView from "./view";

const PublishAd = () => {
  const [error, showError] = useState<boolean>(false);
  const [success, showSuccess] = useState<boolean>(false);
  const [formData, setFormData] = useState<Ad["data"]>();
  const [files, setFiles] = useState<FileList>(null as any);

  const publishAd = async () => {
    if (
      !formData ||
      !formData.category ||
      isEmpty(formData.description) ||
      isEmpty(formData.contactInfo) ||
      !files
    ) {
      showError(true);
      return;
    }
    const result = await publishAdService({ ...formData, files });
    if (result) {
      showError(false);
      showSuccess(true);
    } else showError(true);
  };

  const handleFiles = (files: FileList) => setFiles(files);

  return (
    <PublishAdView
      publishAd={publishAd}
      handleFiles={handleFiles}
      formData={formData}
      setFormData={setFormData}
      files={files}
      error={error}
      success={success}
    />
  );
};

PublishAd.propTypes = {};

export default PublishAd;
