/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import OutsideClickHandler from "react-outside-click-handler";

const Search = ({ searchMode = false, setSearchMode, search }: SearchProps) => {
  return searchMode ? (
    <OutsideClickHandler onOutsideClick={() => setSearchMode(false)}>
      <div className="field has-addons">
        <div className="control">
          <input
            className="input searchInput"
            type="text"
            placeholder="İlan Ara..."
            autoFocus
          />
        </div>
        <div className="control">
          <a className="button searchBtn" onClick={search}>
            <i className="fas fa-search searchIcon"></i>
          </a>
        </div>
      </div>
    </OutsideClickHandler>
  ) : (
    <div onClick={() => setSearchMode(true)}>
      <i className="fas fa-search searchIcon"></i>
      <span onClick={() => setSearchMode(true)}>Arama Yap</span>
    </div>
  );
};

type SearchProps = {
  searchMode: boolean;
  setSearchMode: any;
  search: any;
};

export default Search;
