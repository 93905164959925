/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import styled from "styled-components";
import cn from "classnames";

const Modal = ({
  title,
  content,
  modalActive,
  showModal,
  showIcon = false,
  icon,
}: ModalProps) => {
  return (
    <Styled>
      <div className={cn({ "is-active": modalActive }, "modal")}>
        <div
          onClick={() => showModal(false)}
          className="modal-background"
        ></div>
        <div className="modal-card">
          <section className="modal-card-body">
            <h1 className="title has-text-left">
              {icon && <i className={cn(icon, "icon")}></i>}
              <span>{title}</span>
            </h1>
            <h2 className="subtitle modalContent has-text-left">{content}</h2>

            <div className="field is-grouped is-pulled-right">
              <p className="control">
                <a className="button is-link" onClick={() => showModal(false)}>
                  Tamam
                </a>
              </p>
            </div>
          </section>
        </div>
      </div>
    </Styled>
  );
};

type ModalProps = {
  title: string;
  content: string;
  modalActive: boolean;
  showModal: any;
  showIcon: boolean;
  icon: string;
};

const Styled = styled.div`
  .icon {
    margin-right: 12px;
  }
  .modalContent {
    margin-top: 5px !important;
  }
`;

export default Modal;
