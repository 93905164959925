import styled from "styled-components";

export default styled.div`
  .signup {
    display: block;
    margin-bottom: 10px;
  }
  .image {
    display: flex;
    justify-content: center;
    height: 100%;
  }
  img {
    width: 100%;
    object-fit: contain;
  }
`;
