import styled from "styled-components";

import Screens from "utils/breakpoints";

export default styled.div`
  .section {
    min-height: 400px;
    .topSection {
      display: flex;
      flex-direction: column;
      margin-bottom: 35px;
      .actions {
        display: flex;
        flex-direction: row;
        @media ${Screens.mobile}, ${Screens.tablet} {
          flex-direction: column;
          .action {
            margin-bottom: 1rem !important;
          }
        }
        margin-bottom: 20px;
        .selectCity {
          width: 200px;
          @media ${Screens.mobile} {
            width: 100%;
          }
          margin-right: 15px;
        }
        .button {
          height: 35px;
          margin: 1px 10px 0 0;
        }
      }
      .info {
        color: gray;
      }
    }
    .searchVector {
      width: 400px;
      margin-bottom: 30px;
    }
  }
`;
