import React, { useState } from "react";

import { Button } from "components";
import { sendMail as sendMailService } from "services/contact";
import { isEmpty } from "utils/general";
import { isTabletOrMobile } from "utils/responsiveness";
import Styled from "./style";

const Contact = () => {
  const [error, showError] = useState<boolean>(false);
  const [success, showSuccess] = useState<boolean>(false);
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const sendMail = async () => {
    if (isEmpty(name) || isEmpty(email) || isEmpty(message)) {
      showError(true);
      return;
    }
    const result = await sendMailService(name, email, message);
    if (result) {
      showError(false);
      showSuccess(true);
    } else showError(true);
  };

  return (
    <Styled isTabletOrMobile={isTabletOrMobile()}>
      <section className="section">
        <div className="container">
          <div className="box">
            <div className="field">
              <label className="label">Ad & Soyad</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="Onur Demirtaş"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">E-mail</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  placeholder="deneme@domain.com"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">Mesajınız</label>
              <div className="control">
                <textarea
                  className="textarea"
                  placeholder="Mesajınızı buraya yazınız"
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
            {error && (
              <p className="status error">
                Bir hata oluştu. Lütfen tekrar deneyiniz!
              </p>
            )}
            {!error && success && (
              <p className="status success">Mesajınız başarıyla gönderildi!</p>
            )}
            <br />
            <div className="field is-grouped">
              <div className="control">
                <Button title="Gönder" onClick={sendMail} type="is-link" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Styled>
  );
};

Contact.propTypes = {};

export default Contact;
