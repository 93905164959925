import firebase from "firebase/compat/app";

export const signup = async (email, password) => {
  try {
    const response = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password);

    const token = await response.user.getIdToken();
    return token;
  } catch (error) {
    console.log(error);
    return "error";
  }
};

export const login = async (email, password) => {
  try {
    const response = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);

    const token = await response.user.getIdToken();
    return token;
  } catch (error) {
    console.log(error);
    return "error";
  }
};

export const signout = async () => {
  return new Promise((resolve) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        resolve(true);
      })
      .catch((error) => console.log(error));
  });
};

export const SendEmailVerification = async () => {
  try {
    firebase.auth().currentUser.sendEmailVerification();
  } catch (error) {
    console.log(error);
    return "error";
  }
};
