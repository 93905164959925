/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import { imageAsyncLoader } from "utils/general";

export default (image: string) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  useEffect(() => {
    loadImage();
  }, []);

  const loadImage = async () => {
    const loaded = await imageAsyncLoader(image);
    if (loaded) setImageLoaded(true);
  };

  return imageLoaded;
};
