/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import { Button, Modal, Search } from "components";
import { ROUTES } from "app_constants";
import { signout } from "services/auth";

const Navbar = ({ authenticated = false }: NavbarProps) => {
  const [searchMode, setSearchMode] = useState(false);
  const [modal, showModal] = useState(false);
  const history = useHistory();

  return (
    <Styled>
      <Modal
        title="Hata"
        content="Arama özelliği henüz aktif değildir. Yakında aktif olacaktır!"
        modalActive={modal}
        showModal={showModal}
        showIcon={false}
        icon="fas fa-info-circle"
      />
      <nav
        className="navbar is-transparent is-danger"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src="/patiland-white.png" height="40" alt="Logo White" />
          </Link>

          <a
            role="button"
            className="navbar-burger burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navbarBasicExample"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div id="navbarBasicExample" className="navbar-menu">
          <div className="navbar-start">
            <Link to="/" className="navbar-item">
              Anasayfa
            </Link>
            <div className="navbar-item has-dropdown is-hoverable">
              <Link to="/ads" className="navbar-link">
                İlanlar
              </Link>

              <div className="navbar-dropdown is-boxed">
                <a className="navbar-item" onClick={() => history.push("/")}>
                  Tüm İlanlar
                </a>
                <a
                  className="navbar-item"
                  onClick={() => history.push("/?category=adoption")}
                >
                  Sahiplendirme İlanları
                </a>
                <a
                  className="navbar-item"
                  onClick={() => history.push("/?category=keeper")}
                >
                  Bakıcı İlanları
                </a>
                <a
                  className="navbar-item"
                  onClick={() => history.push("/?category=general")}
                >
                  Diğer İlanlar
                </a>
              </div>
            </div>
            <a className="navbar-item" href="//patreon.com" target="_blank">
              <i className="fas fa-donate donate"></i>
              Bağış Yap
            </a>
            <Link to="/about" className="navbar-item">
              Hakkımızda
            </Link>
            <Link to="/contact" className="navbar-item">
              İletişim
            </Link>
          </div>

          <div className="navbar-end">
            <div className="navbar-item">
              <div className="search link">
                <Search
                  searchMode={searchMode}
                  setSearchMode={setSearchMode}
                  search={() => showModal(true)}
                />
              </div>

              {authenticated ? (
                <>
                  <Link to={ROUTES.PUBLISH_AD} className="navbar-item">
                    <Button
                      className="sendAd"
                      title="İlan Ekle"
                      isOutlined
                      type="is-white"
                    />
                  </Link>
                  <Button title="Çıkış Yap" type="is-white" onClick={signout} />
                </>
              ) : (
                <Button title="Giriş Yap" type="is-white" to={ROUTES.LOGIN} />
              )}
            </div>
          </div>
        </div>
      </nav>
    </Styled>
  );
};

const Styled = styled.div`
  .navbar {
    padding: 0 1rem 0 1rem;
    height: 3.5rem;

    .searchInput,
    .searchBtn {
      border-width: 0;
      &:hover,
      &:focus {
        outline: none;
        outline-width: 0;
        border-width: 0;
        box-shadow: none;
      }
    }
  }
  .navbar-item {
    img {
      max-height: 2rem;
    }
    .donate {
      margin: 2px 5px 0 0;
    }
    .search {
      margin-right: 2rem;

      .searchIcon {
        margin-right: 0.5rem;
      }
    }
  }
  .sendAd {
    margin-right: 0.5rem;
    &:hover {
      background-color: transparent;
    }
  }
`;

type NavbarProps = {
  authenticated: boolean;
};

export default Navbar;
