import styled from "styled-components";
import { grayLight } from "colors";

export default styled.div`
  .section {
    height: 100%;
    background-color: white;
    padding: 3vw 6vw 3vw 6vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    min-height: 50vh;
    .container {
      img {
        margin-bottom: 30px;
        border-radius: 10px;
      }
    }
  }
`;
