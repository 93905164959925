import firebase from "firebase/compat/app";

export const publishAd = async ({
  title,
  category,
  petType,
  description,
  date,
  files,
  contactInfo,
}) => {
  try {
    const fileUploadRes = await uploadFile(Array.from(files));
    if (fileUploadRes) {
      fileUploadRes.forEach((res) => {
        if (res.state !== "success") throw new Error("File Upload Error");
      });
    }
    const response = await firebase
      .firestore()
      .collection("ads")
      .add({
        title,
        category,
        petType,
        description,
        date,
        files: Array.from(files).map((file) => file.name),
        contactInfo,
      });
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const getAds = async () => {
  try {
    const response = await firebase.firestore().collection("ads").get();
    return response.docs.map((doc) => {
      return { id: doc.id, data: doc.data() };
    });
  } catch (error) {
    console.log(error);
    return "error";
  }
};

export const filterAds = async (field, value) => {
  try {
    const response = await firebase
      .firestore()
      .collection("ads")
      .where(field, "==", value)
      .get();
    return response.docs.map((doc) => {
      return { id: doc.id, data: doc.data() };
    });
  } catch (error) {
    console.log(error);
    return "error";
  }
};

export const getAd = async (id) => {
  try {
    const doc = await firebase.firestore().collection("ads").doc(id).get();
    return doc.data();
  } catch (error) {
    console.log(error);
    return "error";
  }
};

export const uploadFile = async (files) => {
  try {
    const metadatas = firebase.storage().ref().putFiles(files);
    return metadatas;
  } catch (error) {
    console.log(error);
  }
};
