import firebase from "firebase/compat/app";

export const sendMail = async (name, email, message) => {
  try {
    const cloudFunction = firebase.functions().httpsCallable("sendMail");
    const result = await cloudFunction({ name, email, message });
    return result;
  } catch (error) {
    console.log(error);
    return "error";
  }
};
