import React from "react";
import Select from "react-select";

import { Button } from "components";
import { isTabletOrMobile } from "utils/responsiveness";
import { adCategories, petTypes, cities } from "app_constants";
import { Ad } from "types";
import Styled from "./style";

const PublishAdView = ({
  publishAd,
  formData,
  setFormData,
  files,
  handleFiles,
  error,
  success,
}: PublishAdViewProps) => {
  return (
    <Styled isTabletOrMobile={isTabletOrMobile()}>
      <section className="section">
        <div className="container">
          <div className="box">
            <h1 className="title">İlan Ekle</h1>
            <h2 className="subtitle">
              Sahiplendirme & bakıcı veya diğer konularda ilan ekleyebilirsiniz.
            </h2>

            <div className="field">
              <label className="label">İlan Başlığı</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  onChange={(e) =>
                    setFormData({ ...formData, title: e.target.value })
                  }
                  placeholder="İlanla ilgili kısa ve öz bir başlık yazınız"
                  value={formData?.title}
                />
              </div>
            </div>
            <div className="field">
              <label className="label">İlan Kategorisi</label>
              <Select
                placeholder="İlan Kategorisi Seçiniz"
                onChange={(value) =>
                  setFormData({ ...formData, category: value })
                }
                options={adCategories as any}
              />
            </div>

            <div className="field">
              <label className="label">Evcil Hayvan Türü</label>
              <Select
                placeholder="Evcil Hayvan Türünü Seçiniz"
                onChange={(value) =>
                  setFormData({ ...formData, petType: value })
                }
                options={petTypes as any}
              />
            </div>

            <div className="field">
              <label className="label">Şehir</label>
              <Select
                placeholder="Şehir Seçiniz"
                onChange={(value) => setFormData({ ...formData, city: value })}
                options={
                  cities.map((city) => {
                    return {
                      label: city,
                      value: city,
                    };
                  }) as any
                }
              />
            </div>

            <div className="field">
              <label className="label">İlan Açıklaması</label>
              <div className="control">
                <textarea
                  className="textarea"
                  rows={10}
                  placeholder="İlanla ilgili tüm detayları buraya yazınız"
                  onChange={(e) =>
                    setFormData({ ...formData, description: e.target.value })
                  }
                  value={formData?.description}
                ></textarea>
              </div>
            </div>

            <div className="field">
              <label className="label">İletişim Bilgisi</label>
              <div className="control">
                <input
                  className="input"
                  type="text"
                  onChange={(e) =>
                    setFormData({ ...formData, contactInfo: e.target.value })
                  }
                  placeholder="Telefon numarası veya e-posta adresi yazınız"
                  value={formData?.contactInfo}
                />
              </div>
            </div>

            <div className="file has-name">
              <label className="file-label">
                <input
                  onChange={(e) => handleFiles(e.target.files as FileList)}
                  className="file-input"
                  type="file"
                  name="photos"
                  multiple
                />
                <span className="file-cta">
                  <span className="file-icon">
                    <i className="fas fa-upload"></i>
                  </span>
                  <span className="file-label">Resim Ekle…</span>
                </span>
              </label>
            </div>

            {files && (
              <div className="files">
                {Array.from(files).map((file) => (
                  <p>- {file.name}</p>
                ))}
              </div>
            )}

            <br />

            {error && (
              <p className="status error">
                Bir hata oluştu. Lütfen tekrar deneyiniz!
              </p>
            )}

            <div className="field is-grouped">
              <div className="control">
                <Button title="İlan Ekle" onClick={publishAd} type="is-link" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Styled>
  );
};

type PublishAdViewProps = {
  formData: Ad["data"] | undefined;
  setFormData: Function;
  publishAd: () => void;
  files: FileList;
  handleFiles: Function;
  error: boolean;
  success: boolean;
};

export default PublishAdView;
