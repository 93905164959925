/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import placeholder from "assets/cat-placeholder.svg";

const Card = ({
  navigate,
  title,
  summary,
  date,
  image,
  link,
  getImageURL,
}: CardProps) => {
  const [localImage, setLocalImage] = useState();

  useEffect(() => {
    loadImage();
  }, []);

  const loadImage = async () => {
    const url = await getImageURL(image);
    setLocalImage(url);
  };

  return (
    <Styled>
      <div className="card">
        <Link to={link}>
          <div className="card-image">
            <figure className="image is-4by3">
              <img
                src={localImage ? localImage : placeholder}
                alt="Placeholder"
                className="link postImg"
                onClick={navigate}
              />
            </figure>
          </div>
        </Link>
        <div className="card-content">
          <Link to="/ad/234">
            <p className="title is-4 link" onClick={navigate}>
              {title}
            </p>
          </Link>

          <div className="content">
            <p className="summary">{summary}</p>
            <b>Kedi</b>
            <p>{date}</p>
          </div>
        </div>
      </div>
    </Styled>
  );
};

type CardProps = {
  navigate?: any;
  title: string;
  summary: string;
  date: string;
  image: string;
  link: string;
  getImageURL: any;
};

const Styled = styled.div`
  .postImg {
    object-fit: cover;
  }
  .title {
    margin-bottom: 10px;
  }
  .summary {
    height: 40px;
  }
`;

export default Card;
