/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import styled from "styled-components";
import { lavender } from "colors";

const Footer = ({ address }: FooterProps) => {
  return (
    <Styled>
      <div className="footer appFooter">
        <div className="content has-text-centered">
          <p className="footerTxt">
            Patiland platformu
            <a
              className="brand"
              href="https://www.onurdemirtas.com"
              target="_blank"
            >
              Onur Demirtaş
            </a>
            tarafından hazırlanmıştır.
          </p>

          <p className="address">{address}</p>

          <a
            href="https://www.linkedin.com/company/remotejobsio"
            target="_blank"
          >
            <i className="fab fa-linkedin socialIcons"></i>
          </a>

          <a href="https://www.facebook.com/remotejobsio" target="_blank">
            <i className="fab fa-facebook-f socialIcons"></i>
          </a>

          <a href="https://www.instagram.com/remotejobsio" target="_blank">
            <i className="fab fa-instagram socialIcons"></i>
          </a>

          <a href="https://twitter.com/remotejobs_" target="_blank">
            <i className="fab fa-twitter socialIcons"></i>
          </a>

          <a
            href="https://www.youtube.com/channel/UC930rc0at5dnJcfYAvJYcuw"
            target="_blank"
          >
            <i className="fab fa-youtube socialIcons"></i>
          </a>
        </div>
      </div>
    </Styled>
  );
};

type FooterProps = {
  address: string;
};

const Styled = styled.div`
  .appFooter {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #546e7a;
    .footerTxt,
    .address {
      color: white;
    }
    .brand {
      color: ${lavender};
      font-weight: bold;
      margin: 0 5px 0 5px;
    }
    .adress {
      margin-bottom: 0;
    }
    .socialIcons {
      margin: 20px 10px 0 0;
      color: black;
    }
  }
`;

export default Footer;
